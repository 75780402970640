<template>
  <Card shadowHover :href="product.variant?.route.publicShow || product.route?.publicShow" eager :ripple="false" elevation="0" class="d-flex flex-column flex-md-row pa-6 pa-md-8 overflow-visible" :class="{ 'min-h-207px': searchResultStyle }">
    <!-- Skeleton loader displayed when loading is true -->
    <template v-if="loading || $isEmpty(product)">
      <div class="d-flex align-center justify-start">
        <VSkeletonLoader class="mx-md-2 w-100 w-md-200px h-207px" color="grey-background" />
      </div>
      <div class="w-100 mx-md-8 mt-2 mt-md-0 d-flex flex-column">
        <VSkeletonLoader class="h-20px w-100px" color="grey-background" />
        <VSkeletonLoader class="mt-2 h-40px w-100 w-md-60" color="grey-background" />
        <VSkeletonLoader class="d-none d-md-block my-3 h-100 w-100" color="grey-background" />
        <VSkeletonLoader class="mt-2 mt-md-0 h-30px w-150px w-md-20" color="grey-background" />
      </div>
      <div class="d-none d-md-flex flex-column align-center justify-end min-w-180px my-auto">
        <VSkeletonLoader class="h-20px w-100 bg-grey-background" />
      </div>
    </template>
    <template v-else>
      <!-- TODO: Strapify, and add ribbon shape -->
      <SalesReasonBanner v-if="product.variant?.salesReason" class="sale-reason" :salesReason="product.variant?.salesReason.name" />
      <div class="d-flex align-center justify-start">
        <VImg :src="product.primaryImage.src" width="200" eager :class="{ 'h-250px': isMobile, 'h-207px': searchResultStyle }" />
      </div>
      <div class="d-flex justify-space-between flex-column flex-md-row w-100 ml-0 ml-md-2">
        <div class="d-flex flex-column w-100 h-100">
          <!-- TODO: Strapify -->
          <div class="d-flex align-center">
            <p class="paragraph-small -no-margin text-grey">ART: {{ product.part_number }}</p>
            <template v-if="isEndOfLife">
              <p class="paragraph-small -no-margin text-grey ms-2">|</p>
              <VIcon size="x-small" icon="far fa-info-circle" class="text-warning my-auto ms-2" />
              <!-- TODO: Strapify -->
              <p class="paragraph-small -no-margin text-warning ml-2 bold">End of Life</p>
            </template>
          </div>
          <h4 class="line-clamp-1">
            {{ product.name }}
            <VTooltip location="top" activator="parent">{{ product.name }}</VTooltip>
          </h4>
          <div>
            <p class="-no-margin attributes line-clamp-2 line-clamp-md-4">
              <template v-for="(attribute, index) in $filter(product.attributes, attribute => $isNotEmpty(attribute.value))" :key="index">
                <span class="">
                  <strong>{{ attribute.name }}:</strong>
                </span>
                <span class="attribute-value text-grey-dark mx-1">
                  {{ valueToString(attribute) }}
                </span>
              </template>
            </p>
          </div>
          <!-- TODO: Strapify -->
          <a :href="product.variant?.route.publicShow || product.route?.publicShow" :aria-label="`${product.name} detailed page`" class="d-none d-md-inline paragraph -no-margin bold mt-auto">
            More details
            <VIcon icon="far fa-arrow-right" class="more-details-arrow ml-1" color="primary" size="24" />
          </a>
        </div>
        <div class="d-flex flex-column justify-center justify-md-space-between mt-4 mt-md-0 ml-md-2 min-w-180px max-w-md-180px">
          <div class="align-self-center justify-center w-120px">
            <StockIndicator :inStock="product.variant?.inStock" :estRestockAt="product.variant?.est_restock_at" />
          </div>
          <div v-if="priceInfo == 'prices'" class="d-flex h-100 w-100 justify-center align-self-end align-center mt-4 mt-md-0">
            <VList class="d-flex align-center overflow-hidden py-0 min-h-md-85px max-h-100px">
              <div class="d-flex flex-column w-100 align-self-start">
                <VListItem v-for="(row, index) in product.variant?.prices" :key="index" class="min-h-21px max-h-21px pa-0">
                  <div class="d-flex justify-center justify-md-end max-w-180px">
                    <p class="paragraph -no-margin bold w-50px text-right text-grey">&ge; {{ row.quantity }}</p>
                    <p class="paragraph -no-margin bold text-no-wrap ml-4 w-80px" :class="{ 'font-bold sale-price-text-color': product.variant?.salesReason }">{{ $moneyFormat(row.price) }}</p>
                  </div>
                </VListItem>
              </div>
            </VList>
          </div>
          <ReplacementProductOrContact v-else :product="product" :priceInfo="priceInfo" />

          <!-- TODO: Strapify and check size -->
          <Button class="cart-button mt-4 mt-md-auto mx-auto w-200px w-md-150px" :prependIcon="cartButton.disabled ? '' : 'fas fa-plus'" :class="{ 'bg-accent': !cartButton.disabled, 'bg-grey': cartButton.disabled }" :color="cartButton.disabled ? 'black' : 'white'" :disabled="cartButton.disabled || addToCartLoading" :loading="addToCartLoading" :size="isMobile ? 'large' : 'small'" @click.prevent="addToCart">{{ cartButton.text }}</Button>
        </div>
      </div>
      <a class="d-md-none position-absolute bottom-35px right-25px" :href="product.variant?.route.publicShow || product.route?.publicShow" :aria-label="`${product.name} detailed page`">
        <VIcon icon="far fa-arrow-right" color="primary" size="24" />
      </a>
    </template>
  </Card>
</template>

<script>
import StockIndicator from '@Components/StockIndicator.vue';
import SalesReasonBanner from '@Components/SalesReasonBanner.vue';
import ReplacementProductOrContact from '@Components/ReplacementProductOrContact.vue';

export default {
  name: 'ProductRow',
  components: { StockIndicator, SalesReasonBanner, ReplacementProductOrContact },
  props: {
    product: {
      type: Object,
      required: true
    },
    searchResultStyle: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addToCartLoading: false
    };
  },
  computed: {
    // TODO: Strapify
    isEndOfLife() {
      return this.product.is_end_of_life;
    },
    stockBadge() {
      switch (true) {
        case this.product.variant?.inStock:
          return { style: 'in-stock', text: 'In Stock' };
        case this.product.variant.est_restock_at !== null: // TODO: Check that this is safe?
          return { style: 're-stock', text: `Back in Stock ${this.$date(this.product.variant.est_restock_at)}` };
        default:
          return { style: 'out-of-stock', text: 'Out of Stock' };
      }
    },
    cartButton() {
      switch (true) {
        case this.product.variant?.allowedForCheckout:
          return { disabled: false, text: 'Add to Cart', color: 'bg-accent' };
        default:
          return { disabled: true, text: 'Unavailable', color: 'bg-grey' };
      }
    },
    priceInfo() {
      switch (true) {
        case this.product.variant?.hasPrices:
          return 'prices';
        case this.$isNotEmpty(this.product.replacementArticle):
          return 'replacement';
        default:
          return 'contact';
      }
    }
  },
  methods: {
    addToCart() {
      this.addToCartLoading = true;
      setTimeout(() => {
        this.$mitt.emit('cart:add', { partNumber: this.product.part_number, variantCode: this.product.variant.code, quantity: 1, snackbar: true });
        this.addToCartLoading = false;
      }, 600);
    },
    valueToString(attribute) {
      if (this.$isEmpty(attribute.value)) {
        return;
      }
      if (attribute.type === 'range') {
        let range = `${attribute.value[0]} – ${attribute.value[1]}`;
        if (attribute.unit) {
          range += ` ${attribute.unit}`;
        }
        return range;
      } else if (attribute.type === 'numeric') {
        let string = attribute.value;
        if (attribute.unit) {
          string += ` ${attribute.unit}`;
        }
        return string;
      } else if (attribute.type === 'boolean') {
        return attribute.value ? 'Yes' : 'No';
      } else if (attribute.type === 'string' && typeof attribute.value === 'object') {
        return attribute.value.join(' | ');
      }
      return attribute.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  &:hover {
    &:hover h4:not(:hover) {
      color: $color-secondary !important;
    }

    :deep(.text-grey) {
      color: $color-grey-dark !important;
    }

    :deep(.more-details-arrow) {
      margin-left: 8px !important;
    }
  }

  .more-details-arrow {
    margin-bottom: 2px;
    transition: margin-left 0.3s ease-in-out;
  }

  .text-grey {
    transition: color 0.3s ease-in-out;
  }
}

.end-of-life {
  border-color: $color-validation-yellow;
}

.stock-clearance {
  border-color: $color-validation-green;
}

.sales-reason {
  left: -15px;
  top: 20px;
}

.cart-button {
  transition: none;
  &:hover {
    background-color: lighten($color-accent, 6%) !important;
  }
}

.sale-price-text-color {
  color: $color-secondary;
}

// .more-details-mobile {
//   position: absolute;
//   bottom: 24px;
//   right: 24px;
// }
</style>
