<template>
  <VueRecaptcha ref="captcha" :sitekey="siteKey" size="invisible" badge="bottomleft" class="d-none" />
</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  name: 'Captcha',
  components: { VueRecaptcha },
  data() {
    return {
      siteKey: window.Techship.application.captchaSiteKey
    };
  },
  methods: {
    execute() {
      this.$refs.captcha.execute();
    },
    reset() {
      this.$refs.captcha.reset();
    }
  }
};
</script>
<style lang="scss" scoped>
:deep(.grecaptcha-badge) {
  visibility: hidden;
}
</style>
