<script setup>
import HeroDark from '@Components/HeroDark.vue';
import { useWindowTechship } from '@/composables/useWindowTechship';
import { ref } from 'vue';
import SubscribeToBlogForm from '@/components/SubscribeToBlogForm.vue';

const { data } = useWindowTechship();
const loading = ref(false);

const scrollToBlogSubscribe = ref(data.value.application?.blogSubscribeModule.ScrollToSubscribeToBlogLabel);
</script>

<template>
  <div>
    <HeroDark :strapi="data?.strapi?.Hero" :scrollToBlogSubsribeFormLabel="scrollToBlogSubscribe" />
    <PageBuilder v-if="data?.strapi.Modules" :modules="data.strapi.Modules" />
    <div class="bg-grey-background pb-10">
      <SubscribeToBlogForm />
    </div>
  </div>
</template>
