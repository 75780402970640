<script setup>
import EmployeeCards from '@/components/EmployeeCards.vue';

const props = defineProps({
  departments: {
    type: Object,
    required: true
  },
  employees: {
    type: Object,
    required: true
  }
});
</script>
<template>
  <div class="container pt-10">
    <div v-for="(department, index) in props.departments" :key="index">
      <h1 class="text-secondary">{{ department.Title }}</h1>
      <div v-if="department?.Image" class="grid w-full">
        <div class="align-end d-print-block h-100 position-relative box-link-image grid-item -col-span-12 -col-span-md-8 -col-span-lg-6" height="100%">
          <VCard class="box-link-card position-relative" :class="{ hover: hover === index }" @mouseenter="hover = index" @mouseleave="hover = null">
            <VImg aspect-ratio="1" class="box-image position-absolute d-print-block w-100 h-100" cover :style="`background-image: url('${department.Image?.url}');`" />
          </VCard>
        </div>
      </div>

      <EmployeeCards :employeesDepartment="department.employees" :employees="props.employees" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.box-image {
  background-position: center;
  background-size: cover;
}

.v-card {
  position: relative;
  width: 100%;
  aspect-ratio: 2 / 1;
  border-radius: 10px;
  box-shadow: none;

  @include is-screen-md {
    aspect-ratio: 3 / 2;
  }
}

.box-link-card {
  box-shadow: 0px 20px 40px 2px rgba(100, 100, 100, 0) !important;
}
</style>
