<script setup>
import HeroDark from '@/components/HeroDark.vue';
import {useWindowTechship} from "@/composables/useWindowTechship";

const { data } = useWindowTechship();
</script>

<template>
  <div>
    <HeroDark v-if="data.strapi.Hero" :strapi="data.strapi.Hero" />
    <PageBuilder v-if="data.strapi?.Modules" :modules="data.strapi?.Modules" :departments="data?.departments" :employees="data?.employees" />
  </div>
</template>
