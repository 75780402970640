<script>
import Captcha from '@Components/Captcha.vue';
import { gsap } from 'gsap';

export default {
  name: 'SubscribeToBlogForm',
  components: { Captcha },
  emits: ['onSuccessfulSubscription'],
  data() {
    return {
      strapiFooter: this.$dom.application.footer.SubscriptionForm,
      strapiBlogSubscribeModule: this.$dom.application.blogSubscribeModule,
      loading: false,
      valid: false,
      email: '',
      name: '',
      captcha: ''
    };
  },
  created() {
    this.$mitt.on('scrollToBlogSubscriptionForm', () => {
      this.$refs.nameRef.focus();
    });
  },
  methods: {
    async checkValidAndStartCaptcha() {
      if (!this.valid) return;
      this.loading = true;
      if (this.captcha === '') {
        this.$refs.captchaRef.execute();
      } else {
        await this.submit(this.captcha);
      }
    },
    async submit(_captcha) {
      this.captcha = _captcha;
      const response = await this.post('/blog/subscribe/', { blogSubscription: { email: this.email, name: this.name, captcha: this.captcha } });
      this.loading = false;
      if (!response) return;
      this.$emit('onSuccessfulSubscription');
    },
    resetCaptcha() {
      this.$refs.captchaRef.reset();
      this.captcha = '';
    }
  }
};
</script>
<!-- <script setup>
import Captcha from '@Components/Captcha.vue';

import { useWindowTechship } from '@/composables/useWindowTechship';
import { ref } from 'vue';
import { axios } from 'axios';

const { data } = useWindowTechship();

const loading = ref(false);
const valid = ref(false);
const email = ref('');
const name = ref('');
const captcha = ref('');
const captchaRef = ref(null);
const nameRef = ref(null);
const strapi =ref(data.application.footer.SubscriptionForm)

// TODO: Add this
// onCreated(() => {
//   this.$mitt.on('scrollToFooterNewsletterSubscriptionForm', () => {
//     nameRef.value.focus();
//   });
// });

async function checkValidAndStartCaptcha() {
  if (!valid.value) return;
  loading.value = true;
  if (captcha.value === '') {
    captchaRef.value.execute();
  } else {
    await submit(this.captcha);
  }
}

async function submit(_captcha) {
  captcha.value = _captcha;
  const response = await axios.post('/blog/subscribe/', { blogSubscription: { email: email.value, name: name.value, captcha: captcha.value } });
  loading.value = false;
  if (!response) return;

  //TODO: Add this event
  // this.$emit('onSuccessfulSubscription');
}

function resetCaptcha() {
  captchaRef.value.reset();
  captcha.value = '';
}
</script> -->
<template>
  <div id="subscribeToBlog" class="container">
    <Card class="pa-6 mb-2 width-card mx-auto mx-lg-0">
      <div class="grid justify-center">
        <span class="grid-item -col-span-12 -col-span-lg-4 text-center text-lg-left justify-self-center">
          <h2>{{ strapiBlogSubscribeModule.Title }}</h2>
          <p class="-no-margin">{{ strapiBlogSubscribeModule.Description }}</p>
        </span>
        <span class="grid-item -col-span-12 -col-span-lg-8 align-self-center">
          <VForm v-model="valid" :disabled="loading" @submit.prevent="checkValidAndStartCaptcha">
            <div class="d-flex ga-6 flex-column flex-lg-row">
              <TextField id="blogName" v-model="name" type="text" :placeholder="strapiBlogSubscribeModule.NameLabel" class="width-input" />
              <EmailTextField id="blogEmail" v-model="email" type="email" :placeholder="strapiBlogSubscribeModule.EmailLabel" class="width-input" />
              <div class="mx-auto mx-lg-0">
                <Button class="bg-primary" color="white" :loading="loading" :disabled="loading" type="submit">
                  {{ strapiBlogSubscribeModule.SubscribeLabel }}
                  <VIcon icon="fa-solid fa-chevron-right" size="x-small" class="ml-2" />
                </Button>
              </div>
              <Captcha ref="captchaRef" @verify="submit" @expired="resetCaptcha" />
            </div>
          </VForm>
        </span>
      </div>
    </Card>
    <p class="caption width-card mx-auto mx-lg-0 -no-margin text-grey text-center text-lg-right">
      <span>
        {{ strapiFooter.ReCaptchaText }}
        <a href="https://policies.google.com/privacy" aria-label="go to googles privacy policy" class="text-grey">{{ strapiFooter.PrivacyPolicyLink }}</a>
        {{ strapiFooter.AndText }}
        <a href="https://policies.google.com/terms" aria-label="go to googles policy terms" class="text-grey">{{ strapiFooter.TermsOfServiceLink }}</a>
        {{ strapiFooter.ApplyText }}
      </span>
    </p>
  </div>
</template>
<style lang="scss" scoped>
.width-card {
  width: 100%;
  max-width: 440px;
  @include is-screen-lg() {
    max-width: none;
  }
}
.width-input {
  width: 100%;
  max-width: 390px;
  @include is-screen-lg() {
    max-width: none;
  }
}
</style>
