<script setup>
import SubscribeToBlogForm from '@/components/SubscribeToBlogForm.vue';
import BlogHero from './components/BlogHero.vue';
import BlogShareButtons from './components/BlogShareButtons.vue';
import { useWindowTechship } from '@/composables/useWindowTechship';
import { ref } from 'vue';

const { data } = useWindowTechship();
const loading = ref(false);
</script>

<template>
  <div class="mt-20 pb-10 bg-grey-background">
    <BlogHero :strapi="data.strapi" :authors="data.authors" />
    <PageBuilder v-if="data.strapi?.Modules" :modules="data.strapi?.Modules" class="pt-4 pb-10" />
    <BlogShareButtons v-if="data.strapi?.ShareButtons" :strapi="data.strapi.ShareButtons" class="w-100 max-w-950px mx-auto" />
    <SubscribeToBlogForm class="mt-10" />
  </div>
</template>

<style lang="scss" scoped>
.type-tag {
  border-radius: 4px;
  width: fit-content;
  padding: 4px 8px;
}
.technicalBg {
  background: rgba($color-accent, 0.1);
}
.generalBg {
  background: rgba($color-primary, 0.1);
}
.productBg {
  background: rgba($color-secondary, 0.1);
}
</style>
