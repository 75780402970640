import { ref, onBeforeMount } from 'vue';

export function useWindowTechship() {
  const data = ref(null);
  data.value = window.Techship;

  return {
    data
  };
}
