<template>
  <Transition name="fade">
    <slot />
  </Transition>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
