<template>
  <div class="container">
    <VBreadcrumbs v-if="visible" :items="mappedBreadcrumbs" class="breadcrumbs flex-wrap -no-margin py-2 pl-1" :class="{ 'text-white': isDarkBackground }">
      <template #prepend>
        <a href="/" class="pe-1"><VIcon icon="far fa-house" size="default" /></a>
        <li class="v-breadcrumbs-divider">
          <VIcon size="x-small" icon="fas fa-chevron-right" class="opacity-30" />
        </li>
      </template>
      <template #divider>
        <VIcon size="x-small" icon="fas fa-chevron-right" class="opacity-30" />
      </template>
    </VBreadcrumbs>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  data() {
    return {
      breadcrumbs: this.$breadcrumbs,
      isDarkBackground: false
    };
  },
  computed: {
    visible() {
      return this.$utils.isNotEmpty(this.breadcrumbs);
    },
    mappedBreadcrumbs() {
      return this.$utils.map(this.breadcrumbs, (href, title) => {
        return { title: title, href: href };
      });
    }
  },
  created() {
    this.$mitt.on('breadcrumbsBackground', darkOrLight => {
      this.isDarkBackground = darkOrLight === 'dark';
    });
  },
  mounted() {
    const heroDark = document.querySelector('.heroDark');
    if (heroDark) this.isDarkBackground = true;
  }
  // created() {
  //   this.$mitt.on('setBreadcrumbs', breadcrumbs => {
  //     console.log('breadcrumbs', breadcrumbs);
  //     // this.breadcrumbs = breadcrumbs;
  //   });
  // }
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  position: absolute;
  z-index: 10;
  top: 88px;
  @include caption;
  @include is-screen-md {
    @include paragraph('small');
  }

  &.text-white * {
    color: white !important;
  }
}
.v-breadcrumbs {
  i {
    --v-icon-size-multiplier: 0.7;
  }
  .v-breadcrumbs-item {
    padding: 0;
  }
  :deep(.v-breadcrumbs-divider) {
    padding: 0 4px;
    // padding: 0;
    @include is-screen-md {
      padding: 0 8px;
    }
  }
}

:deep(.v-breadcrumbs-item--link) {
  text-decoration: none !important;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $color-primary;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::after {
    opacity: 1;
  }
}
</style>
